.credit-card {
  cursor: pointer;
}

.credit-card .ion-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.credit-card .ion-icon {
  margin-left: 8px;
  color: var(--ion-color-primary);
}

.routeone-button-container {
  margin-top: 16px;
}
.routeoneSvg .st0 {
  fill: #0071ce;
}
.routeoneSvg .st1 {
  fill: #76bc21;
}
.routeoneSvg {
  height: 19px;
  max-height: 19px;
  enable-background: new 0 0 704 132;
}

.open-full-credit-app {
  float: right;
}
