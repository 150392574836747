.lead-info {
  padding-bottom: env(safe-inset-area-bottom, 5px);

  .inventory-like-grid {
    border-bottom: solid 1px var(--ion-border-color);
    padding: 0;

    .inventory-item {
      border-bottom: solid 1px var(--ion-border-color);
      &:nth-last-child(-0n + 2):not(:nth-child(even)),
      &:last-child {
        border-bottom: none;
      }
      cursor: pointer;
      padding-top: 10px;
      padding-bottom: 10px;
      position: relative;
      max-width: 50%;
      text-align: center;

      &:nth-child(odd) {
        border-right: solid 1px var(--ion-border-color);
      }

      .detail {
        display: flex;
        margin-top: 10px;
        > * {
          flex: 0 0 50%;
        }
        .price {
          text-align: right;
        }
      }

      .stock {
        font-size: 10px;
        color: var(--ion-color-danger);

        &.in-stock {
          color: var(--ion-color-success);
        }
      }

      ion-img {
        object-fit: cover;
        margin: auto;
        height: 80px;
        width: 120px;
      }
    }
  }
}

.trade-in {
  .trade-pricing {
    ion-icon {
      margin: 0 5px;
    }
  }
}
