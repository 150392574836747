.t-menu {
  height: 48px;
  width: 48px;
  position: relative;

  .dot {
    background-color: var(--ion-link-color);
    border: solid 2px var(--ion-background-color);
    box-sizing: content-box;
    border-radius: 10px;
    display: block;
    height: 8px;
    position: absolute;
    right: 8px;
    top: 13px;
    width: 8px;
    pointer-events: none;
  }
}
