.call-row-actions {
  margin-top: 0;
}

.call-row-tools,
.call-row.expanded,
.call-row-actions {
  transition: background ease-in-out 0.15s;
  --background: var(--ion-zebra-item-background);
}

.call-row {
  cursor: pointer;

  &.expanded {
    --ion-item-border-color: transparent;
  }

  h3 {
    font-weight: 500;
  }

  .call-icon {
    height: 20px;
    width: 20px;
  }

  .call-row-detail,
  .text-row-detail {
    font-size: 12px;
    text-align: right;

    > * {
      display: block;
    }
  }

  .lead-details {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .duration {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    margin-top: 3px;

    .no-audio-icon {
      font-size: 16px;
    }
  }
}

.no-audio-available {
  --background: transparent;
  font-size: 14px;
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 6px;
}
