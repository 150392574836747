.price-range-container {
  width: 100%;
  ion-range {
    --bar-background-active: var(--ion-color-secondary);
    --bar-background: var(--ion-color-step-550);
    --pin-background: var(--ion-color-secondary);
    --pin-color: var(--ion-color-secondary-contrast);
    --knob-size: 15px;
    --height: 20px;
    margin-top: 10px;
    padding: 0 10px 0 5px;

    html.ios &,
    html.android & {
      padding: 0 10px;
    }
  }

  .price-range {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 0;
    margin-bottom: 10px;

    .price-range-label {
      font-size: 12px;
      flex: 0 0 50%;
      &:last-child {
        text-align: right;
      }
    }
  }
}
