.reports {
  padding-top: 0;
  margin-bottom: 20px;
  ion-card {
    height: 100% !important;
    ion-card-header {
      text-align: center;
    }
  }

  ion-card-header {
    border-bottom: solid 1px var(--ion-border-color);
  }

  .users-grid {
    ion-row {
      flex-wrap: nowrap;
    }

    ion-col {
      --ion-grid-columns: 4;
      width: 25%;
    }
  }
  .provider-grid {
    --inner-padding-end: 0;

    ion-col {
      --ion-grid-columns: 4;
      padding: 10px;
      width: 25%;
    }

    ion-row:not(:last-child) {
      border-bottom: solid 1px var(--ion-border-color);
    }
  }

  .column-header {
    border-bottom: solid 1px var(--ion-border-color);
    color: var(--ion-color-step-650);
    font-size: 0.75rem;
  }
}
