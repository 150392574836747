.giphy-picker-button {
  align-items: center;
  display: flex;
  cursor: pointer;
  font-size: 18px;
  font-weight: 800;
  justify-content: center;
}

.giphy-picker {
  --width: 320px;
  --height: 65vh;
}
