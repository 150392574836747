.blast-preset-card {
  ion-card {
    height: 100%;
  }
  .last-item {
    margin-bottom: 45px;
  }
  ion-footer {
    position: absolute;
    bottom: 0;
  }
}
