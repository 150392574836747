.chat-bubble {
  @media screen and (max-width: 600px) {
    &.ad-preview .bubble {
      max-width: 90vw;
    }
  }

  .video-in-progress {
    position: absolute;
    top: 12px;
    left: 5px;
  }

  .bubble {
    border-radius: 15px;
    background-color: var(--ion-color-success);
    color: white;
    display: inline-block;
    padding: 7px 7px 0;
    max-width: 60vw;
    min-width: 50px;

    .plt-ios & {
      padding: 7px 7px 5px;
    }

    min-height: 30px;
    position: relative;
    text-align: left;

    > img,
    > video {
      border-radius: 8px;
    }

    iframe {
      max-width: 100%;
    }
    .reset-pause {
      color: var(--ion-color-success-contrast);
    }
    .send-to-document-center {
      color: var(--ion-color-success-contrast);
      display: block;
    }
  }

  .translation {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-size: 14px;
    padding: 10px;
    margin-bottom: 10px;

    .translation-language {
      font-size: 10px;
      opacity: 0.6;
    }
  }

  .message-text {
    padding: 0px 10px 5px;
    max-width: 300px;
    word-break: break-word;
    a {
      --color: var(--text-color) !important;
    }
  }

  &.yellow .bubble {
    background-color: var(--ion-color-warning) !important;
    // white is hard to read in a yellow bubble
    color: #000 !important;
    &,
    & a {
      color: var(--ion-item-background, '#FFF');
    }
    .chat-internal-only {
      // white is hard to read in a yellow bubble
      color: #000 !important;
    }
  }
  &.beige .bubble {
    background-color: var(--ion-color-beige) !important;
    color: #000;
    .chat-internal-only {
      // white is hard to read in a beige bubble
      color: #000 !important;
    }
  }
  &.yellow.orange .bubble {
    background-color: var(--ion-color-orange) !important;
  }
  &.yellow.red .bubble {
    background-color: var(--ion-color-danger) !important;
  }
  &.outgoing {
    .chat-label {
      margin-right: 20px;
    }

    &.call .bubble::before {
      border-right-color: var(--ion-color-tertiary) !important;
    }

    &.yellow .bubble::before {
      border-right-color: var(--ion-color-warning);
    }
    &.yellow.red .bubble::before {
      border-right-color: var(--ion-color-danger);
    }
    &.yellow.orange .bubble::before {
      border-right-color: var(--ion-color-orange);
    }
    &.beige .bubble::before {
      border-right-color: var(--ion-color-beige);
    }
    .bubble {
      background-color: var(--ion-color-secondary);
    }

    .bubble::before {
      border-right-color: var(--ion-color-secondary);
      bottom: -8px;
      right: -4px;
      transform: rotate(-30deg);
    }
  }

  &.call {
    .chat-internal-only {
      color: var(--ion-color-teriary-contrast);
    }

    .bubble {
      background-color: var(--ion-color-tertiary) !important;
      color: white !important;
    }
  }

  &.incoming {
    &.call .bubble::before {
      border-left-color: var(--ion-color-tertiary) !important;
    }
    &.beige .bubble::before {
      border-left-color: var(--ion-color-beige) !important;
    }
    .chat-label {
      margin-left: 20px;
    }

    .dark & .bubble,
    .dark & .bubble a {
      color: var(--ion-item-background);
    }

    &.yellow .bubble {
      a {
        color: var(--ion-item-contrast);
      }

      &::before {
        border-left-color: var(--ion-color-warning);
      }
    }

    .bubble::before {
      border-left-color: var(--ion-color-success);
      bottom: -8px;
      left: -4px;
      transform: rotate(30deg);
    }
  }

  .chat-label {
    overflow: visible;
  }

  .bubble::before {
    border-radius: 2px;
    border: solid 13px transparent;
    content: '';
    display: block;
    position: absolute;
  }

  .chat-details,
  .chat-internal-only {
    align-items: center;
    display: flex;
    font-size: 12px;
    color: var(--ion-color-step-650);
    margin-top: 4px;

    ion-icon {
      margin-right: 5px;
    }
  }

  .chat-internal-only {
    color: var(--ion-item-background);
    padding: 0 5px;
    margin: 0 0 5px;
    justify-content: flex-end;
  }

  &.outgoing {
    text-align: right;

    .chat-details {
      justify-content: flex-end;
    }
  }

  .audio-background {
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 35px;
    margin: 5px 0;
    padding: 0 5px;

    &.audio-range {
      padding-right: 20px !important;
    }
  }
}
