.add-lead-modal {
  @media screen and (max-height: 490px) {
    .lead-message-box {
      .t-input {
        height: 75px;
        width: 100%;
        textarea {
          height: 100%;
        }
      }
    }
  }

  .lead-message-box {
    .t-input {
      height: 125px;
      width: 100%;
      textarea {
        height: 100%;
      }
    }
  }
}
