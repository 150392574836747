.no-padding-button {
  --padding-start: 2px !important;
  --padding-end: 2px !important;
  width: 26px;
  height: 26px;
  padding: 0;
  align-self: center;
}

.chat-tools {
  --background: var(--background);
  --padding-start: 0;
  --padding-end: 0;
  height: 0;
  overflow: hidden;
  pointer-events: none;
  transition: height ease-in-out 0.25s;
  
  ion-col {
    text-align: center;
  }
  
  .chat-tool {
    font-size: 18px;
    height: auto;
    margin: 0 0 2rem 0;
    position: relative;
    
    &::after {
      font-size: 9px;
      color: var(--text-color);
      content: attr(title);
      left: 50%;
      transform: translate(-50%, 0);
      position: absolute;
      text-wrap: nowrap;
    }
  }

  &.visible {
    border-bottom: solid 1px var(--ion-border-color);
    height: 100px;
    pointer-events: unset;
  
    @media screen and (max-width: 800px) {
      height: 200px;
    }
  }

  .archived {
    color: var(--ion-color-danger);
  }
}