.media-preview {
  --max-width: 90vw;
  --max-height: 90vh;

  .header-tools {
    .dark & {
      background-color: rgba(0, 0, 0, 0.3);
    }

    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5px 10px;
    position: absolute;
    top: 5px;
    right: 5px;

    .close-icon,
    .share-icon {
      .dark & {
        color: white;
      }

      color: black;
      cursor: pointer;
      opacity: 0.8;
      font-size: 22px;
      margin: auto 3px;
    }
  }
}
