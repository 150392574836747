.personalized-lead {
  ion-header {
    .split-pane-visible & {
      display: none;
    }
  }

  .featured-appointment {
    background-color: var(--ion-item-background);
    border-radius: 6px;
    overflow: hidden;
    h2 {
      font-size: 26px;
      padding: 20px 0 10px;
      margin: 0;
    }
  }

  .assigned-to-info {
    align-items: center;
    display: flex;

    ion-avatar {
      height: 40px;
      flex: 0 0 40px;
    }
  }

  .hours-info {
    color: var(--ion-color-success);
    font-size: 13px;
    font-weight: 600;
  }

  .welcome-text {
    border-bottom: solid 1px var(--ion-border-color);
    color: var(--ion-color-step-700);
    font-weight: 400;
    margin: 0 5px 15px;
    padding-bottom: 5px;

    strong {
      color: var(--ion-text-color);
    }
  }

  .trade-info {
    border-radius: 6px;
    padding: 0;
    margin: 10px 10px 0;
    ion-item {
      --background: var(--ion-color-step-150);
    }
  }

  ion-col h2 {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 18px;

    &:not(.subtitle) {
      margin-left: 10px;
    }

    span {
      flex: 1 1 auto;
    }

    ion-icon {
      cursor: pointer;
      font-size: 26px;
      flex: 0 0 auto;
      margin-right: 5px;
    }
  }

  .slides-container {
    user-select: none;
  }

  .featured-vehicle {
    position: relative;
    margin-left: 10px;

    ion-slides {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    img {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      object-fit: cover;
      height: 350px;
      width: 100%;
    }

    .arrow {
      filter: drop-shadow(2px 1px 2px rgba(0, 0, 0, 0.3));
      font-size: 40px;
      top: 50%;
      position: absolute;
      z-index: 10;
      transform: translateY(-50%);

      &:first-child {
        left: 0;
      }

      &:last-child {
        right: 0;
      }

      &.disabled {
        opacity: 0.3;
      }
    }

    .featured-vehicle-text {
      ion-button {
        padding-top: 0;
        margin-top: 0;
      }

      align-items: flex-start;
      display: flex;
      flex-direction: row;
      .selling-price {
        margin-left: 20px;
      }
    }

    .vehicle-info {
      background-color: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(8px);
      align-items: center;
      display: flex;
      padding: 10px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;

      .vehicle-price {
        margin-left: 10px;
        font-size: 18px;
        font-weight: 500;
      }

      .vehicle-info-text {
        flex: 1 1 auto;

        strong {
          font-size: 20px;
        }

        .vehicle-info-mt {
          display: block;
          color: #e0e0e0;
          font-size: 15px;
        }
      }

      color: white;
    }
  }

  .featured-vehicle-buttons {
    ion-col:last-child {
      text-align: right;
    }
  }

  h2 {
    margin-bottom: 0;
  }

  .inventory-search {
    ion-col {
      align-items: center;
      display: flex;
      padding-top: 0;
      padding-bottom: 0;

      .show-filters {
        font-size: 22px;
        margin-left: 10px;
      }
    }

    .searching-indicator {
      border-bottom-left-radius: 120px;
      border-bottom-right-radius: 120px;
      position: absolute;
      bottom: 12px;
      left: 3px;
      width: calc(100% - 6px);
    }
  }

  .find-us-title {
    align-items: center;
    display: flex;

    span {
      flex: 1 1 auto;
    }

    ion-icon {
      cursor: pointer;
      flex: 0 0 auto;
    }
  }

  .map {
    height: 200px;
    width: 100%;
    overflow: hidden;
    border-radius: 6px;
  }

  .inventory {
    background-color: var(--ion-color-step-150);
    border: solid 1px var(--ion-border-color);
    border-radius: 6px;
    cursor: pointer;
    height: 100%;

    ion-ripple-effect {
      border-radius: 6px;
    }

    img {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      object-fit: cover;
      min-height: 150px;
      height: 150px;
      width: 100%;
      margin: 0;
    }

    .heart-icon {
      font-size: 20px;
      position: absolute;
      top: 10px;
      right: 10px;
    }

    ion-button {
      padding: 3px 15px;
      margin: auto;
      width: 100%;
      margin-bottom: 10px;
    }

    .vehicle-info {
      padding: 5px 10px;
    }

    .vehicle-detail {
      align-items: center;
      display: flex;
      margin-bottom: 0;

      > * {
        flex: 0 0 50%;

        &:last-child {
          text-align: right;
        }
      }
    }
  }

  .featured-video {
    margin-left: 10px;
    border-radius: 6px;
    height: auto;
    width: calc(100% - 10px);
  }

  .featured-vehicle-buttons {
    background-color: var(--ion-color-step-150);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-left: 10px;
    width: calc(100% - 10px);
  }

  .customer-survey {
    ion-text {
      width: 100%;
    }

    .survey-overview {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;

      strong {
        flex: 1 1 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .rating-stars {
        flex: 0 0 auto;
      }
    }

    q {
      quotes: '“' '”';
      color: var(--ion-color-step-650);
      font-size: 12px;
    }
  }
}

.personalized-videos {
  .personalized-video {
    position: relative;
    border-radius: 6px;
    min-height: 150px;

    img {
      border-radius: 6px;
      cursor: pointer;
      object-fit: cover;
      height: 150px;
      width: 100%;
    }

    .play-button {
      filter: drop-shadow(2px 1px 2px rgba(0, 0, 0, 0.3));
      font-size: 50px;
      position: absolute;
      top: 50%;
      left: 50%;
      pointer-events: none;
      transform: translate(-50%, -50%);
    }
  }
}

.large-video {
  --width: auto;
  --max-height: 80vh;

  video {
    height: auto;
    width: 80vw;
  }

  ion-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    color: var(--ion-color-step-500);
    font-size: 30px;
  }
}

.personalized-lead-collapsed-header {
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);

  .dark & {
    background: rgba(0, 0, 0, 0.55);
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.4);
  }

  backdrop-filter: blur(7px);
  display: flex;
  position: fixed;
  top: -50px;
  left: 0;
  right: 0;
  padding: 0 20px;
  overflow: hidden;
  transition: top cubic-bezier(0.55, 0, 0.52, 0.99) 0.15s;
  height: 50px;
  z-index: 10;

  &.visible {
    top: 0;
  }

  .greeting {
    flex: 1 1 auto;
  }

  .quick-actions {
    align-items: center;
    display: flex;
    flex: 0 0 auto;

    .contact-action {
      @media screen and (max-width: 400px) {
        display: none;
      }

      padding: 0 15px;

      &:last-child {
        padding-right: 10px;
      }

      ion-icon {
        font-size: 22px;
      }
    }
  }
}
