.vehicle-showcase {
  &.loading {
    display: none;
  }

  .features {
    li {
      font-size: 14px;
      margin-bottom: 4px;
    }
  }

  .thank-you {
    text-align: center;
  }

  .slides-container {
    position: relative;
    user-select: none;

    .dark & {
      background: rgb(21, 22, 24);
      background: linear-gradient(
        180deg,
        rgba(21, 22, 24, 1) 0%,
        rgba(34, 36, 40, 1) 100%
      );
    }

    .arrow {
      font-size: 40px;
      top: 50%;
      position: absolute;
      z-index: 10;
      transform: translateY(-50%);

      .plt-mobile & {
        font-size: 20px;

        &:first-child {
          left: 2px;
        }

        &:last-child {
          right: 2px;
        }

        &.disabled {
          display: none;
        }
      }

      &:first-child {
        left: 10px;
      }

      &:last-child {
        right: 10px;
      }

      &.disabled {
        opacity: 0.3;
      }
    }

    .photos {
      padding: 40px 0;
      margin: auto 20px;

      .plt-mobile & {
        padding: 10px 0;
      }
    }
  }

  .quick-view {
    margin-top: 0;
    height: 100%;

    .ios & {
      margin: 0 5px;
    }

    ion-card-header {
      border-bottom: solid 1px var(--ion-border-color);
    }
  }

  ion-header {
    text-align: center;
    .dark & ion-toolbar {
      --background: rgba(21, 22, 24, 1);
      --border-color: transparent;
    }
  }

  ion-footer {
    box-shadow: 0 -15px 50px rgba(0, 0, 0, 0.35);
    .dark & ion-toolbar {
      --background: var(--ion-item-background);
      --border-color: transparent;
    }
  }

  .dealer-info {
    ion-row {
      align-items: center;
      border-bottom: solid 1px var(--ion-border-color);

      ion-col {
        h4 {
          margin: 0;
        }
      }
    }
  }

  .callout {
    font-size: 14px;
    margin: 10px 15px 0;
    text-align: center;
  }

  .prepared-text {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    font-size: 12px;
    color: var(--ion-color-step-700);
    margin: 10px auto;
    justify-content: center;
    text-align: center;

    ion-icon {
      margin: 0 5px;
    }
  }

  .powered-by {
    align-items: center;
    color: var(--ion-color-step-600);
    display: flex;
    font-size: 10px;
    justify-content: center;
    margin: 25px auto;
    text-transform: uppercase;
    img {
      height: 10px;
      margin-left: 5px;
    }
  }

  .map {
    background-color: var(--ion-item-background);
    border-radius: 6px;
    margin-top: 20px;
    height: 200px;
    width: 100%;
  }
}

.large-vehicle-photo {
  --width: 80vw;
  --max-height: 80vh;

  img {
    height: auto;
    width: 80vw;
  }

  ion-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    color: var(--ion-color-step-500);
    font-size: 30px;
  }
}
