.real-time-card {
  ion-card {
    height: 400px;
  }
  .shield-icon {
    color: #8ec252;
    margin-right: 3px;
  }
  ion-card-content {
    margin-bottom: 25px;
    overflow: auto;
    max-height: 295px;
  }
  ion-footer {
    position: absolute;
    bottom: 0;
  }
}
