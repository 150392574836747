.viewed-vehicles-grid {
  padding: 10px;
  gap: 10px; /* Add spacing between rows */
}

.vehicle-item {
  display: flex;
  flex-direction: row; /* Align image and text horizontally */
  align-items: flex-start; /* Align items at the top */
  text-align: left;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  transition: transform 0.2s ease-in-out;
}

.vehicle-item:hover {
  transform: scale(1.02); /* Subtle hover effect */
}

.vehicle-item img {
  max-width: 120px;
  max-height: 80px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 15px;
}

.vehicle-item .detail {
  flex-grow: 1; /* Ensure text takes up remaining space */
  line-height: 1.5;
  display: flex;
  flex-direction: column; /* Stack text vertically */
}

.vehicle-item .detail strong {
  font-size: 16px;
  color: #000;
  margin-bottom: 5px;
}

.vehicle-item .stock-number,
.vehicle-item .vin {
  font-size: 14px;
  color: #555;
  margin-bottom: 2px;
}

.vehicle-item .vin {
  color: #888;
}

.vehicle-item .detail span {
  display: block; /* Force each text to take its own line */
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 140px;
  min-width: 100%;
  max-width: 100%;
  overflow: hidden; /* Truncate text if it’s too long */
}
