.t-click-to-edit-item {
  &.button {
    --background: transparent;
  }

  .display-text {
    background-color: rgba(0, 0, 0, 0.1);

    .dark & {
      background-color: rgba(255, 255, 255, 0.1);
    }

    border-radius: 10px;
    margin: 10px 0;
    padding: 10px;
    width: 100%;

    p {
      margin: 0 0 10px 0;
    }
  }

  .edit-message {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    margin-bottom: 5px;
    ion-icon {
      flex: 0 0 auto;
      margin-right: 5px;
    }
  }
}

// #ion-overlay-11 > div {
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
// }

.t-click-to-edit-item-modal {
  .t-modal-toolbar {
    padding: 0;
    margin: 0;
    --inner-padding-end: 0;
    --inner-padding-start: 0;
  }

  .button-grid {
    flex: 0 0 auto;
  }

  .t-input {
    background-color: rgba(0, 0, 0, 0.1);

    .dark & {
      background-color: rgba(255, 255, 255, 0.1);
    }

    color: var(--ion-text-color);
    max-width: 100%;
    margin-top: 0;
    display: block;
    padding: 0 10px;
    flex: 1 1 100%;

    .native-textarea {
      display: block;
      height: 100%;
    }
  }
}
