.color-swatch-container {
  text-align: center;
  margin-bottom: 20px;
}

.color-swatch {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.color-name {
  font-size: 12px;
  margin-top: 8px;
  color: #666;
  word-break: break-word;
}
