.user-reserve-calendar-slot {
  padding-bottom: 30px;
  ion-icon {
    font-size: 1.2rem;
    vertical-align: bottom;
  }
  .col-1 {
    padding-bottom: 30px;
  }
  .col-2 {
    border-top: 1px solid #f3f3f3;
  }
  @media screen and (min-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 748px;
    border-radius: 8px;
    .col-1 {
      padding: 35px;
    }
    .col-2 {
      border-top: 0 none;
      border-left: 1px solid #f3f3f3;
      min-height: 700px;
    }
  }
  .t-datepicker-label-control {
    font-size: 21px;
  }
  .react-datepicker {
    top: 35px;
  }
  .react-datepicker__time-list-item.outside-hours, .react-datepicker__time-list-item--disabled {
    display: none;
  }
  .react-datepicker__header--time {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .react-datepicker__time-list-item {
    background: white;
    border-right: 1px solid #aeaeae;
    position: relative;
  }
  .react-datepicker__time-list:before {
    content: 'Not available';
    position: absolute;
    left: 0;
    font-size: .65rem;
    top: 5px;
    text-align: center;
    width: 100%;
  }
}
// Dark mode
.dark .user-reserve-calendar-slot {
  .col-2 {
    border-top: 1px solid #222327;
  }
  @media screen and (min-width: 768px) {
    .col-2 {
      border-top: 0 none;
      border-left: 1px solid #222327;
    }
  }
}
