.t-segment-item {
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-start: 10px;

  html:not(.ios) & {
    --inner-padding-start: 0;
    --inner-padding-end: 0;
  }

  .t-item-segment {
    @media screen and (max-width: 600px) {
      font-size: 14px;
      padding: 0;
      min-width: auto;
    }
  }
}
