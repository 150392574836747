.add-calendar-appointment-modal {
  ion-label {
    --color: var(--ion-color-step-650) !important;
  }

  ion-content {
    --background: var(--ion-item-background);
  }
  .t-error-message {
    display: block;
    position: relative;
    z-index: 1;
    small {
      position: absolute;
      top: -46px;
      right: 50px;
    }
  }
  .lead-btn {
    margin: 15px;
  }
  .tags-input {
    width: 100%;
    height: 100%;
    min-height: 65px;
  }
  // iOS safari fix
  .tagify__input,
  .tagify__tag>div>[contenteditable] {
    -webkit-user-select: text;
    user-select: text;
    -webkit-user-modify:read-write;
  }
  .t-tags-wrap {
    width: 100%;
  }
  .tagify {
    border: 0 none;
  }
  .tagify {
    height: 100%;
    padding-top: 22px;
  }
  .tagify__input {
    height: 100%;
    padding: 15px 0 0;
    margin: 0;
  }
  // Hide the default placeholder, we will use Ionic's
  .tagify__input::before,
  .tagify__input:empty::before {
    display: none;
  }
  .tagify__tag {
    margin: 5px 5px 5px 5px;
  }
}
