.weather {
  user-select: none;
  .weather-icon {
    height: auto;
    width: 80px;
    margin: 0 !important;
  }

  .weather-temp {
    font-size: 2rem;
    margin: 0 15px 0 0;
  }

  .weather-city {
    font-size: 0.875rem;
    font-weight: 500;
  }
}

.client-hours-icon {
  padding: 0 10px 0 0;
}

.client-hours {
  user-select: none;
  .detail {
    color: var(--ion-color-danger);
    &.open {
      color: var(--ion-color-success);
    }
  }
}

.client-hours-popover {
  --background: var(--ion-item-background);
  --width: 300px;
  --height: fit-content;
}
