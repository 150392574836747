.t-popper {
  $arrowSize: 10px;
  $color: var(--ion-color-tertiary);
  color: var(--ion-color-tertiary-contrast);
  font-size: 12px;
  max-width: 150px;
  text-align: center;
  z-index: 100;

  &:not(.no-bubble) .t-popper-content {
    background-color: $color;
    border-radius: 3px;
    padding: 10px;
  }

  &[data-placement*='bottom'] {
    &::before {
      border: solid $arrowSize transparent;
      border-bottom-color: $color;
    }
  }
  &[data-placement*='top'] {
    &::before {
      border: solid $arrowSize transparent;
      border-top-color: $color;
    }
  }
  &[data-placement*='right'] {
    &::before {
      border: solid $arrowSize transparent;
      border-right-color: $color;
    }
  }
  &[data-placement*='left'] {
    &::before {
      border: solid $arrowSize transparent;
      border-left-color: $color;
    }
  }
  &:not(.no-bubble)::before {
    content: '';
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }
}
