@import "./animations.scss";

ion-item {
  --highlight-color-focused: none;
}

.action-sheet-title.sc-ion-action-sheet-md {
  height: auto;
}

ion-item.no-contain {
  contain: none;
  overflow: visible;
}

ion-label {
  line-height: 1.2;
}

h2 {
  font-weight: 500 !important;
}

ion-badge {
  margin: auto 5px;
}

ion-select {
  --placeholder-opacity: 1;
}

.error-message {
  flex: 1 1 100%;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translate(0, -50%);
  text-align: center;
}

.inbound-queue-debug {
  table th {
    text-align: left;
    border-bottom: solid 3px var(--ion-border-color);
    padding-bottom: 5px;
  }
}

.flex-buttons {
  display: flex;
  flex-direction: row;
  padding: 5px 15px;
}

ion-item.archived {
  --background: rgba(var(--ion-color-danger-rgb), 0.15);
  --background-hover: rgba(var(--ion-color-danger-rgb), 0.2);

  .dark & {
    --background: rgba(var(--ion-color-danger-rgb), 0.08);
    --background-hover: rgba(var(--ion-color-danger-rgb), 0.1);
  }
}

ion-item.paused {
  --background: rgba(var(--ion-color-warning-rgb), 0.15);
  --background-hover: rgba(var(--ion-color-warning-rgb), 0.2);

  .dark & {
    --background: rgba(var(--ion-color-warning-rgb), 0.08);
    --background-hover: rgba(var(--ion-color-warning-rgb), 0.1);
  }
}

ion-item.inbound {
  --background: rgba(var(--ion-color-secondary-rgb), 0.15);
  --background-hover: rgba(var(--ion-color-secondary-rgb), 0.2);

  .dark & {
    --background: rgba(var(--ion-color-secondary-rgb), 0.08);
    --background-hover: rgba(var(--ion-color-secondary-rgb), 0.1);
  }
}

.ios .dark ion-toast {
  --button-color: var(--ion-color-success) !important;
}

.input-background {
  --background: var(--login-item-background);
}

.text-pill-rect {
  background-color: var(--ion-color-step-400);
  border-radius: 4px;
  color: var(--ion-color-step-50);
  padding: 2px 6px;
  text-transform: uppercase;
}

.unread-dot {
  background-color: var(--ion-color-secondary);
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-top: 18px;
  margin-right: 10px;
  align-self: start;
  opacity: 0;

  &.unread {
    opacity: 1;
  }
}

ion-header {
  ion-button {
    height: auto !important;
  }
}

.md .dark .refresher-refreshing-icon,
.md .dark .refresher-pulling-icon {
  background: var(--ion-border-color) !important;
  border-color: var(--ion-border-color) !important;
}

.menu-avatar {
  --background: var(--ion-item-background);
  border-bottom: solid 1px var(--ion-border-color);
}

.client-selector {
  border-top: solid 1px var(--ion-border-color);
  font-size: 12px;

  .client-buttons {
    margin-left: 5px;
  }
}

ion-card-subtitle {
  margin-top: 10px;
}

.ellipsis {
  display: block !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.light-text-color {
  --color: var(--ion-color-step-650) !important;
}

.detail {
  align-items: center;
  color: var(--ion-color-step-650);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 12px !important;
}

.subtitle {
  align-items: center;
  color: var(--ion-color-step-750);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 14px;
}

.footer-client-title {
  color: var(--ion-color-step-800);
  font-size: 13px;
}

.zebra:nth-child(even) {
  --ion-item-background: var(--ion-zebra-item-background);
}

.offline {
  color: var(--ion-color-danger);

  ion-text {
    font-size: 12px;
    line-height: 1.3;
    margin-left: 5px;
  }
}

/* custom reusable classes */

.no-margin {
  margin: 0;
}

.margin-top {
  margin-top: 10px;
}

.margin-left {
  margin-left: 10px;
}

.margin-right {
  margin-right: 10px;
}

.margin-bottom {
  margin-bottom: 10px;
}

.margin {
  margin: 10px;
}

.no-padding {
  padding: 0;
  --padding-start: 0;
  --padding-end: 0;
}

.btn-sm-padding,
.sm-padding {
  --padding-start: 5px;
  --padding-end: 5px;
}

.border-radius-bottom {
  border-bottom: none !important;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.border-radius-top {
  border-top: none !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.border-radius-left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.border-radius-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.border-radius {
  border-radius: 4px;
}

.link-button {
  background-color: transparent;
  border: none;
  color: var(--ion-link-color);
  cursor: pointer;
  display: inline;
  font-size: 1rem;
  line-height: 1.2rem;
  margin: 1.5rem 0;
  padding: 0;
}

.pointer {
  cursor: pointer;
}

.no-events {
  pointer-events: none;
  user-select: none;
}

.text-align-right {
  text-align: right;
}

.light {
  color: var(--ion-color-step-650);
}
.dark .pac-container {
  background-color: var(--ion-background-color);
  border-top-color: var(--ion-border-color);
  .pac-item {
    border-top-color: var(--ion-border-color);
    color: var(--ion-text-color);
    &,
    .pac-item-query,
    .pac-matched {
      color: var(--ion-text-color);
    }

    &:hover {
      background-color: var(--ion-item-background);
    }
  }
}

.ios {
  ion-card {
    margin: 10px;

    ion-card-title {
      font-size: 18px;
    }
  }

  ion-item-divider {
    --background: var(--ion-background-color);
    --color: var(--ion-color-step-500);
    font-size: 14px;
  }
}

.password-reset-modal {
  --max-width: 600px;

  ion-content {
    --background: var(--ion-background-color);
  }

  ion-item {
    margin: auto 20px;
  }

  ion-text {
    text-align: center;
    p {
      padding: 0 20px;
    }
  }
}

.can-go-back {
  ion-menu-button,
  .t-menu .dot {
    display: none;
  }
}

.menu-item-selected {
  --background: var(--menu-item-selected-background-color);
  --background-hover: var(--menu-item-selected-background-color);
  --color: var(--menu-item-selected-color);
  --transition: none;
}

.menu-item-selected > * {
  fill: var(--menu-item-selected-color);
}

.no-results {
  color: var(--ion-color-step-850);
  display: block;
  padding: 20px 0;
  text-align: center;
}

.text-message-thread {
  --background: var(--ion-item-background);
}

ion-content::part(scroll) {
  overflow-y: auto !important;
}

.emoji-picker-button,
.giphy-picker-button {
  color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.54);
  padding-right: 5px;
}

.send-button {
  ion-icon {
    background-color: var(--ion-color-secondary);
    border-radius: 100%;
    color: var(--ion-color-secondary-contrast);
    --padding: 0 !important;
    --margin: 0;
    height: 18px;
    width: 18px;
  }

  &.outline {
    ion-icon {
      background: none;
      border: solid 1px var(--ion-text-color);
      color: var(--ion-text-color);
    }
  }
}

.offline-container,
.blasts-upgrade-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  height: 100%;
  margin: 60px auto;

  @media screen and (max-width: 500px) {
    padding: 0 15px;
  }

  .offline-text-icon-container,
  .blasts-upgrade-icon-container {
    align-items: center;
    display: flex;
    justify-content: center;

    ion-icon {
      color: var(--ion-text-color);
      flex: 0 0 auto;
      margin-right: 15px;
    }

    ion-text {
      color: var(--ion-text-color);
      flex: 1 1 auto;
      font-weight: 500;
    }
  }

  .offline-icon,
  .blasts-upgrade-icon {
    font-size: 50px;
  }
}

.login {
  display: flex;

  ion-grid {
    ion-row {
      height: 100%;
      align-self: stretch;
      margin: auto;
    }
    min-width: 70vw;
    max-width: 600px;
  }
}

.forgot-password-link {
  display: block;
  margin-top: 20px;
}

.filters-row {
  border-bottom: solid 1px var(--ion-border-color);
  padding: 5px 0;
  overflow-y: hidden;
  overflow-x: visible;
  white-space: nowrap;
  ion-chip {
    flex: 0 0 auto;
  }

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.clocked-in-status {
  color: var(--ion-color-danger);
  margin: 0;
  font-size: 10px;
  ion-icon {
    font-size: 14px;
    margin-right: 5px;
  }

  --padding-end: 0;
  --padding-start: 0;
}

ion-item.needs_call {
  --background: rgba(var(--ion-color-orange-rgb), 0.15);
  --background-hover: rgba(var(--ion-color-orange-rgb), 0.2);

  .dark & {
    --background: rgba(var(--ion-color-orange-rgb), 0.08);
    --background-hover: rgba(var(--ion-color-orange-rgb), 0.1);
  }
}

ion-item.is_email {
  --background: rgba(var(--ion-color-beige-rgb), 0.15);
  --background-hover: rgba(var(--ion-color-beige-rgb), 0.2);

  .dark & {
    --background: rgba(var(--ion-color-beige-rgb), 0.08);
    --background-hover: rgba(var(--ion-color-beige-rgb), 0.1);
  }
}

ion-item.soft_opt_out {
  --background: rgba(var(--ion-color-tertiary-rgb), 0.15);
  --background-hover: rgba(var(--ion-color-tertiary-rgb), 0.2);

  .dark & {
    --background: rgba(var(--ion-color-tertiary-rgb), 0.08);
    --background-hover: rgba(var(--ion-color-tertiary-rgb), 0.1);
  }
}

.alert-wrapper {
  --min-width: 300px;
}

.unsafe-img {
  filter: blur(8px);
}

.pdfModal {
  align-items: center;
  justify-content: center;
  &::part(content) {
    min-width: 80%;
    min-height: 80%;
  }
}
.dashboard-contact-details-container {
  display: flex;
  align-items: center; /* vertically center the content */
}
.dashboard-contact-details {
  margin-left: 10px; /* add some space between the icon and the text */
}

.vertical-center-space {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
