.popover-selector {
  --height: 300px;
  --width: 80vw;
  --max-height: 600px;
  --max-width: 600px;

  .searchbar-background {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: var(--ion-color-step-50);
  }
  
  .popover-searchbar {
    --background: var(--ion-item-background, #ffffff);
  }

  .dark & {
    --ion-item-background: var(--ion-color-step-100);
  }

  .popover-content-container {
    overflow-y: auto;
  }

  .focused {
    --background: var(--menu-item-selected-background-color);
    --color: var(--menu-item-selected-color);
    --transition: none;
  }
}
