.pause-modal {
  html.plt-desktop & {
    --height: 90vh;
  }

  .pause-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .t-datepicker-container {
    flex: 0 0 auto;
  }

  .pause-message {
    background-color: var(--ion-item-background);
    margin: 0;
    height: 100%;
    flex: 1 1 auto;

    .native-textarea {
      height: 100%;
      padding: 10px;
    }
  }
}
