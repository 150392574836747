.audio-range {
  --knob-size: 15px;
  min-width: 180px;
}

.call-duration {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 11px;
  justify-content: center;
  text-align: center;

  ion-icon {
    margin-right: 3px;
  }
}
