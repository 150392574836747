.auto-complete-results {
  --ion-item-background: var(--ion-background-color);
  border: solid 1px var(--ion-border-color);
  border-radius: 6px;
  cursor: pointer;
  overflow: auto;
  padding: 0;
  display: none;
  margin: 0;
  position: absolute;
  max-height: 250px;
  width: 300px;
  z-index: 100;
  transform: translateY(calc(env(safe-area-inset-bottom) * -1));

  &.visible {
    display: block;
  }

  &.align-right {
    transform: translateX(-100%);
  }

  .selected {
    --background: var(--menu-item-selected-background-color);
    --color: var(--menu-item-selected-color);
  }
}
