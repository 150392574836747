.t-datetime-item {
  &.range {
    ion-datetime {
      position: relative;
      min-width: 140px;

      @media screen and (max-width: 600px) {
        font-size: 14px;
        padding: 0;
        min-width: auto;
      }

      &:last-child {
        text-align: right;
      }
    }
  }
}
