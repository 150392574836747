// .wizard-contents {
//   height: 100%;
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .wizard-card {
//   height: calc(100% - 25px);
//   width: 100%;
//   display: flex;
//   flex-direction: column;
// }

// .wizard-card-content {
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
// }

.wizard-button-container {
  display: flex;
  justify-content: space-between;
}