@keyframes row-collapse {
  from {
    height: 100px;
    opacity: 1;
  }

  to {
    height: 0;
    opacity: 0;
  }
}

.rowCollapse {
  animation: row-collapse;
  animation-duration: 0.15s;
  animation-timing-function: ease-out;
  height: 0;
  opacity: 0;
}

$menu-transition-curve: cubic-bezier(0.55, 0, 0.52, 0.99);
$menu-transition-timing: 0.25s;

// .main-menu-items {
//   transition: transform $menu-transition-curve $menu-transition-timing;
//   &:not(.visible) {
//     transform: translateX(-100%);
//   }
// }

.main-menu-items,
.sub-menu-items {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  overflow-y: auto;
  background: var(--ion-item-background);
  transition: transform $menu-transition-curve $menu-transition-timing;
}

.main-menu-items {
  transform: translateX(0);
}

.sub-menu-items {
  transform: translateX(100%);
}

.sub-menu-items.visible {
  transform: translateX(0);
}

.main-menu-items:not(.visible) {
  transform: translateX(-100%);
}
