.training-video {
  video {
    display: block;
    height: auto;
    width: 100%;
    margin: 0 auto 20px;
  }
}

.training-question {
  .question-text {
    color: var(--ion-text-color);
    margin-bottom: 20px;
  }

  .question-answers {
    margin-bottom: 20px;
  }
}

.training-video,
.training-question,
.training-results {
  ion-card-header {
    border-bottom: solid 1px var(--ion-border-color);
    margin-bottom: 20px;
  }

  margin-top: 0;
}

.training-quiz {
  .lesson-name {
    padding: 20px 18px 10px;

    .lesson {
      font-size: 14px;
    }

    .lesson-category {
      display: block;
      font-size: 18px;
      color: var(--ion-color-step-550);
      margin-bottom: 3px;
    }
  }
}

.training-progress {
  @media screen and (max-width: 500px) {
    display: none;
  }

  display: flex;
  flex-direction: row;
  margin: 0 10px 5px;

  .training-progress-item {
    align-items: center;
    background-color: var(--ion-border-color);
    padding: 3px 5px;
    display: flex;
    ion-icon {
      flex: 0 0 auto;
      margin-right: 5px;
    }

    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &:not(:first-child) {
      &:before {
        content: '';
        position: absolute;
        top: 3px;
        left: 0;
        width: 3px;
        height: 8px;
        background: var(--ion-background-color);
        transform: skew(40deg, 0deg);
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 3px;
        left: 0;
        width: 3px;
        height: 8px;
        background: var(--ion-background-color);
        transform: skew(-40deg, 0deg);
      }
    }

    position: relative;
    justify-content: center;
    flex: 1 1 auto;

    span {
      flex: 0 0 auto;
      font-size: 12px;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-transform: uppercase;
    }
  }
}

.training-results {
  .quiz-results {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 40px auto 10px;

    ion-icon {
      font-size: 70px;
      margin-bottom: 20px;
    }
    h2 {
      font-size: 24px;
      margin-bottom: 40px;
    }
  }
}
