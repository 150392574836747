.t-color-picker-item {
  .selected-color {
    background: attr(data-color);
    border: solid 1px var(--ion-border-color);
    display: inline-block;
    height: 25px;
    width: 25px;
  }
}

.color-picker {
  position: absolute;
  z-index: 100;
  transform: translateX(-100%);
}
