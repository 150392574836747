.t-chart {
  ion-card-header {
    border-bottom: solid 1px var(--ion-border-color);
    margin-bottom: 10px;
  }

  &.gauge {
    ion-card-content {
      padding-bottom: 0;
    }
  }
  .chart-label,
  .gauge-label {
    color: var(--ion-text-color);
    font-size: 1.75rem;
    font-weight: 500;
    text-align: center;
    margin: 10px auto 0 auto;
  }
}
