.add-blast-modal {
  .reachable-audience {
    font-weight: bold;
  }

  .status-chips {
    ion-chip {
      ion-label {
        flex: 1 1 auto;
      }
      width: 100%;
    }
  }
}
