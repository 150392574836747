$out-of-range-color: var(--ion-color-step-500);
$out-of-range-bg-color: var(--ion-color-step-150);
$calendar-border: var(--ion-border-color);
$cell-border: rgba(220, 220, 220, 0.2);
$border-color: var(--ion-border-color);
$btn-color: var(--ion-text-color);
$today-highlight-bg: rgba(var(--ion-color-secondary-rgb), 0.1);
$current-time-color: rgba(var(--ion-color-primary-rgb), 0.5);
@import 'react-big-calendar/lib/sass/styles';

.appointments-calendar {
  background-color: var(--ion-item-background);

  color: var(--ion-text-color);
  button {
    appearance: none;
    outline: none;
  }

  height: calc(100vh - 80px);

  font-size: 12px;

  .rbc-toolbar {
    .rbc-btn-group:first-child {
      button {
        background-color: transparent !important;
        border-color: rgb(204, 204, 204) !important;
        color: var(--ion-text-color) !important;
      }
    }

    @media screen and (max-width: 600px) {
      flex-direction: row;

      .rbc-btn-group {
        display: flex;
        flex: 1 1 100%;
        margin-bottom: 3px;

        > button {
          flex: 1 1 33%;
        }

        &:first-child {
          order: 2;
        }

        &:last-child {
          order: 1;
        }
      }

      .rbc-toolbar-label {
        order: 3;
        margin-top: 5px;
      }
    }
  }

  .rbc-active,
  button:active,
  button:focus {
    background-color: var(--ion-color-step-550) !important;
    color: var(--ion-color-step-100);
  }

  .rbc-active,
  button:hover {
    background-color: var(--ion-color-step-600) !important;
    color: var(--ion-color-step-100) !important;
  }

  margin: 5px;
  .rbc-event {
    &:focus {
      appearance: none;
      outline: none;
    }

    &.status-set {
      background-color: var(--ion-color-medium);
      border-color: var(--ion-color-medium-tint);
      color: var(--ion-color-medium-contrast);
    }

    &.status-showed {
      background-color: var(--ion-color-secondary);
      border-color: var(--ion-color-secondary-tint);
      color: var(--ion-color-secondary-contrast);
    }

    &.status-showed_lost {
      background-color: var(--ion-color-tertiary);
      border-color: var(--ion-color-tertiary-tint);
      color: var(--ion-color-tertiary-contrast);
    }

    &.status-cancelled {
      background-color: var(--ion-color-danger);
      border-color: var(--ion-color-danger-tint);
      color: var(--ion-color-danger-contrast);
    }

    &.status-sold {
      background-color: var(--ion-color-success);
      border-color: var(--ion-color-success-tint);
      color: var(--ion-color-success-contrast);
    }

    &.status-missed {
      background-color: var(--ion-color-warning);
      border-color: var(--ion-color-warning-tint);
      color: var(--ion-color-warning-contrast);
    }
  }

  .rbc-agenda-table {
    .status-set {
      .rbc-agenda-event-cell {
        background-color: var(--ion-color-medium);
        border-color: var(--ion-color-medium-tint);
        color: var(--ion-color-medium-contrast);
      }
    }

    .status-showed {
      .rbc-agenda-event-cell {
        background-color: var(--ion-color-secondary);
        border-color: var(--ion-color-secondary-tint);
        color: var(--ion-color-secondary-contrast);
      }
    }

    .status-cancelled {
      .rbc-agenda-event-cell {
        background-color: var(--ion-color-danger);
        border-color: var(--ion-color-danger-tint);
        color: var(--ion-color-danger-contrast);
      }
    }

    .status-sold {
      .rbc-agenda-event-cell {
        background-color: var(--ion-color-success);
        border-color: var(--ion-color-success-tint);
        color: var(--ion-color-success-contrast);
      }
    }

    .status-showed_lost {
      .rbc-agenda-event-cell {
        background-color: var(--ion-color-tertiary);
        border-color: var(--ion-color-tertiary-tint);
        color: var(--ion-color-tertiary-contrast);
      }
    }

    .status-missed {
      .rbc-agenda-event-cell {
        background-color: var(--ion-color-warning);
        border-color: var(--ion-color-warning-tint);
        color: var(--ion-color-warning-contrast);
      }
    }
  }
}

@import 'react-big-calendar/lib/sass/styles';
