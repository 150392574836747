.inventory-modal {
  html.plt-desktop & {
    --height: 90vh;
  }

  ion-card {
    position: relative;
    width: 100%;
  }

  .inventory-search-bar {
    display: flex;
    padding-left: 10px;

    html.plt-desktop & {
      ion-searchbar {
        flex: 1 1 auto;
      }
    }

    ion-badge {
      flex: 0 0 auto;
      margin-left: 10px;
    }
  }

  .t-modal-toolbar {
    ion-item {
      --background: transparent;
      --background-hover: transparent;
      --padding-start: 10px;
    }
  }

  .t-modal-toolbar {
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-end: 0;

    .t-expandable-item-group {
      .expandable-title {
        --padding-start: 10px;
      }

      &.expanded {
        border-bottom: none;
        .expandable-title {
          background-color: transparent;
          border-bottom: none;
        }
      }
    }
  }

  .dark & ion-card-content {
    background-color: rgba(255, 255, 255, 0.1);
    ion-item {
      --background: transparent;
    }
  }
}
