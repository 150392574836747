.quick-replies-modal {
  .t-modal-toolbar {
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-end: 0;

    ion-toolbar {
      padding: 0 10px;
    }
  }

  .category-select {
    width: 100%;
  }
}

.confirm-delete-popover::part(content) {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.confirm-delete-popover::part(backdrop) {
  background: rgba(0, 0, 0, 0.05);
}

ion-button.quick-replies-footer-button {
  margin: 0 .5rem;
}