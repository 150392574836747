.metric-card {
  ion-card-header {
    border-bottom: solid 1px var(--ion-border-color);
  }

  display: flex;
  flex-direction: column;
  ion-card-content {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    strong {
      color: var(--ion-text-color);
      flex: 0 0 auto;
      font-size: 3rem;
    }
    font-size: 2rem;
    text-align: center;
  }
}
