.t-expandable-item-group {
  .expandable-group-items {
    transition: height cubic-bezier(0.55, 0, 0.52, 0.99) 0.2s;
    height: 0;
    overflow: hidden;
  }

  .expandable-title {
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    transition: height cubic-bezier(0.55, 0, 0.52, 0.99) 0.2s;
    user-select: none;
    ion-icon {
      margin-right: 10px;
    }
  }

  &.expanded {
    border-bottom: solid 5px var(--ion-border-color);
    height: auto;
    .expandable-group-items {
      overflow: visible;
      height: auto;
    }

    .expandable-title {
      background-color: var(--ion-item-background);
      color: var(--ion-text-color);
    }
  }
}
