.notification-row {
  &.unread {
    h2 {
      font-weight: bold;
    }
  }

  p {
    color: var(--ion-color-step-800);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
