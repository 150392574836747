.t-ai-replies-popover {
  --background: var(--ion-item-background);
  --width: 480px;
  --max-width: 87vw;
  --height: 466px;

  @media screen and (max-width: 500px) {
    --max-width: 96vw;
  }
}

.ai-replies-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ai-close {
  float: right;
  z-index: 3;
}

.ai-title {
  max-width: 70%;
}

/* GPT */
.ai-loading {
  pointer-events: all;
  cursor: pointer;
  opacity: 0.58;
  animation: bounce 1s ease-out infinite, fade 2s ease-in-out infinite;

}

.ai-loading ion-icon {
  animation: pulse 2s ease-out infinite;
}

.ai-loading:after {
  content: "";
  bottom: 0px;
  right: 0;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: fade 2s ease-in-out infinite;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.1);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes fade {
  0% {
    opacity: .5;
  }

  50% {
    opacity: .67;
  }

  100% {
    opacity: .5;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(-8px);
  }

  50% {
    transform: translateY(0);
  }

  75% {
    transform: translateY(-3px);
  }

  100% {
    transform: translateY(0);
  }
}