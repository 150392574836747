.appointment-modal {
  ion-label {
    --color: var(--ion-color-step-650) !important;
  }

  ion-content {
    --background: var(--ion-item-background);
  }

  .lead-btn {
    margin: 15px;
  }
}
